<template>
  <div class="card card-gray br-16">
    <div class="card-body">
      <div class="row">
        <div class="col-md-8 summary-text">
          Chargeable Weight
        </div>
        <div class="col-md-4 summary-text">
          {{
            weightDetail.totalChargeableWeight !== ""
              ? weightDetail.totalChargeableWeight + " lbs"
              : ""
          }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 summary-text">
          Volumetric Weight
        </div>
        <div class="col-md-4 summary-text">
          {{ weightDetail.totalVolumetricWeight + " lbs"}}
          <div v-if="weightDetail.shipmentLoader" class="shipment-loader"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 summary-text">
          Actual Weight
        </div>
        <div class="col-md-4 summary-text">
          {{ weightDetail.totalWeight !== "" ? weightDetail.totalWeight + " lbs" : "" }}
        </div>
      </div>

      <!-- Display the title "Pallet spots" and the total number of pallets if available -->
      <div class="row">
        <div class="col-md-8 summary-text">
          Pallet spots
        </div>
        <div class="col-md-4 summary-text">
          {{ weightDetail.totalNumOfPallets !== "" ? weightDetail.totalNumOfPallets : "" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    // Component props
    selectedTab: {
      type: String,
    },
    weightDetail: {
      type: Object
    }
  },
};
</script>
<style></style>
