<template>
  <div class="wrapper">
    <div class="body-container row">
      <left-panel :activeMenuName="activeMenuName"></left-panel>
      <div class="col-lg-10 right-panel-scroll plr-30 mt-4">

        <div class="row right-panel-header">
          <div class="col-md-4">
            <search-bar
            />
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-4">
            <profile-header/>
          </div>
        </div>

        <page-heading-card
            title="Account information"
            subTitle=""
            imageName="user.png"
        />

        <div class="mt-5 mb-5 section-b">
          <div class="headline-30 mb-4">
            Personal Information
          </div>

          <div class="row">
            <div class="col-md-6">
              <base-input
                  name="name"
                  type="text"
                  class="mb-0 input-v1"
                  label="Name"
                  v-model="name"
                  @input="(event) => changeName(event)"
              ></base-input>

              <span v-if="invalidName" class="validate-error">
                {{ nameErrorMsg }}
              </span>
            </div>

            <div class="col-md-6">
              <base-input
                  name="email"
                  type="text"
                  class="mb-0 input-v1"
                  label="Email ID"
                  :disabled="readOnlyEmail"
                  v-model="email"
              ></base-input>
            </div>
          </div>

          <div class="mt-3 mb-3">
            <a
                class="reset-password-link"
                @click="showModal"
            >
              Click here to reset your password
            </a>
          </div>

          <collapse-wrapper title="Organizational Info" v-model="organizationalInfoCollapse">
            <div class="row mt-4">
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Organization/team"
                    :disabled="true"
                    v-model="organizationName"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Role"
                    :disabled="true"
                    v-model="userType"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="email"
                    type="text"
                    class="mb-0 input-v1"
                    label="Plan"
                    :disabled="true"
                ></base-input>
              </div>
            </div>
          </collapse-wrapper>

          <collapse-wrapper title="Authenticate Dimensioner" v-model="dimensionerInfoCollapse">
            <div class="row mt-4" style="height:200px">
              <div class="col-md-4">
                <label class="mb-0">Dimensioner</label>
                <choices-single
                    ref="choicesSingle"
                    id="dimensioner"
                    :options="dimensionerList"
                    :value="dimensionerKey"
                    v-model="dimensionerKey"
                    @input="event => selectDimensionerKey(event)"
                >
                  <option value="0">{{ defaultDimensioner }}</option>
                </choices-single>
              </div>
              <div class="col-md-4">
                <base-input
                    name="username"
                    type="text"
                    class="mb-0 input-v1"
                    label="Username"
                    v-model="username"
                    @input="onUsernameChange"
                    autocomplete="off"
                ></base-input>
              </div>
              <div class="col-md-4">
                <base-input
                    name="Password"
                    :type="showPassword ? 'text' : 'password'"
                    class="mb-0 input-v1"
                    label="API Password"
                    v-model="password"
                    autocomplete="off"
                >
                </base-input>
                <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"
                  class="password-toggle-icon"
                  @click="togglePasswordVisibility">
                </i>
              </div>
            </div>
          </collapse-wrapper>

          <div class="text-right">
            <base-button
                class="btn-theme-083C2F"
                @click="updateProfile"
            >
              Save
            </base-button>
          </div>
        </div>
      </div>

      <change-password-modal
          ref="changePasswordModal"
          :email="email"
      />

    </div>
  </div>
</template>

<script>
import LeftPanel from '@/layout/LeftPanel';
import SearchBar from '@/views/components/SearchBar';
import ProfileHeader from '@/layout/ProfileHeader';
import PageHeadingCard from '@/views/components/PageHeadingCard';
import CollapseWrapper from '@/views/components/CollapseWrapper.vue';
import ChangePasswordModal from '@/views/components/ChangePasswordModal.vue';
import {API, masterAPI} from '@/api/API';
import ChoicesSingle from '@/components/SingleSelect.vue';

export default {
  components: {
    ChoicesSingle,
    LeftPanel,
    SearchBar,
    ProfileHeader,
    PageHeadingCard,
    CollapseWrapper,
    ChangePasswordModal,
  },
  data() {
    return {
      activeMenuName: 'AccountSetting',
      name: '',
      email: '',
      readOnlyEmail: true,
      organizationName: '',
      userType: '',
      nameErrorMsg: 'Name field is required',
      invalidName: false,
      organizationalInfoCollapse: true,
      dimensionerList: [
        {
          id: 'cargoSpectre',
          text: 'cargoSpectre',
        },
        {
          id: 'freightSnap',
          text: 'freightSnap',
        },
      ],
      dimensionerKey: '',
      username: '',
      password: '',
      dimensionerInfoCollapse: true,
      showApiKeyError: false,
      dbDimensionerKey: '',
      defaultDimensioner: "Select Dimensioner",
      showPassword: false,  // To track if the password is visible
    };
  },
  methods: {
    /**
     * Updates the user profile with the provided name.
     */
    async updateProfile() {
      if (this.name == '') {
        this.invalidName = true;
      } else {
        let firstName = '';
        let lastName = '';
        let fullName = this.name;

        // Split the full name into first and last name if it contains a space
        if (fullName.includes(' ')) {
          let splitName = fullName.split(/(?<=^\S+)\s/);
          firstName = splitName[0];
          lastName = splitName[1];
        } else {
          firstName = fullName;
        }

        let bodyPayload = JSON.stringify({
          firstName: firstName,
          lastName: lastName,
        });

        let response = await masterAPI(
            API.API_ENDPOINT.user,
            API.API_METHOD.put,
            undefined,
            bodyPayload,
        );

        if (response.status == 200) {
          this.$toast.success('Name updated succesfully', {timeout: 1000});
          this.getUser();
          this.invalidName = false;
        } else {
          let error = response.data.message;
          this.$toast.error(error.replaceAll('"', ''), {timeout: 2100});
        }
      }

      if (this.username !== '' && this.password !== '') {
        this.updateDimensioner();
      } 

      if (this.username == '' && this.password == '') {
        this.removeDimensioner();
      }
    },
    /**
     * Fetches the user information from the API and updates the relevant data properties.
     */
    async getUser() {
      let response = await masterAPI(
          API.API_ENDPOINT.user,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (response.status == 200) {
        let user = response.data;
        this.email = user.email;
        this.name = user.name;
        this.organizationName = user.organizationName;
        this.userType = user.type;
        this.$store.commit(
            'setUser',
            Object.assign(this.$store.state, {user}),
        );
      }
    },
    /**
     * Updates the dimensioner details by making an API request.
     * 
     * @returns {Promise<void>} - A promise that resolves when the dimensioner is updated or an error occurs.
    */
    async updateDimensioner() {
      let bodyPayload = JSON.stringify({
        key: this.dimensionerKey,
        auth: {
          username: this.username,
          password: this.password,
        },
      });

      let dimensionerResp = await masterAPI(
          API.API_ENDPOINT.dimensioner,
          API.API_METHOD.put,
          undefined,
          bodyPayload,
      );

      if (dimensionerResp.status == 200) {
        this.setDimensionerDetails(dimensionerResp.data);
        this.showPassword = false;
      } else {
        let error = dimensionerResp.data.message;
        this.$toast.error(error.replaceAll('"', ''), {timeout: 2100});
      }
    },
    /**
     * Delete the dimensioner by making a delete request to the API.
    */
    async removeDimensioner () {
      let response = await masterAPI(
        API.API_ENDPOINT.dimensioner,
        API.API_METHOD.delete,
        undefined,
        undefined
      );
      
      if (response.status == 200) {
        this.dimensionerKey = this.defaultDimensioner;
        this.dbDimensionerKey = this.defaultDimensioner;
        document.querySelector("#dimensioner .choices__item").innerText = this.defaultDimensioner;
      }
    },
    /**
     * Fetches the dimensioner information from the API and updates the component's data properties.
    */
    async getDimensioner() {
      let dimensionerResp = await masterAPI(
          API.API_ENDPOINT.dimensioner,
          API.API_METHOD.get,
          undefined,
          undefined,
      );

      if (dimensionerResp.status == 200) {
        this.setDimensionerDetails(dimensionerResp.data);
      }
    },
    /**
     * Sets the dimensioner details if the response contains a valid key.
    */
    setDimensionerDetails(dimensioner) {
      if (dimensioner.key) {
        this.dimensionerKey = dimensioner.key;
        this.username = dimensioner.auth.username;
        this.password = dimensioner.auth.password;
        this.showApiKeyError = false;
        this.dbDimensionerKey = dimensioner.key;
        this.$nextTick(() => {
            this.$refs.choicesSingle.setChoiceDropdownByValue();
        });
      }
    },
    /**
     * Checks if the Name empty or not
     */
    changeName(newName) {
      if (newName === '') {
        this.invalidName = true;
      } else {
        this.invalidName = false;
      }
    },
    /**
     * Opens the change password modal.
     */
    showModal() {
      this.$refs.changePasswordModal.openModal();
    },
    /**
     * Handles the logic when the username is changed.
     * If the username, password, and dimensionerKey are filled in, 
     * the password is cleared, and an error message is displayed
     * requiring the user to re-enter the API password key for security reasons.
    */
    onUsernameChange() {
      if (this.dimensionerKey !== "" && this.password != "") {
        if (!this.showApiKeyError) {
          this.password = '';
          this.$toast.error('Changing the username requires entering the API Password key for security reasons.', {
            timeout: 5000,
          });
          this.showApiKeyError = true;
        }
      } else {
        this.showApiKeyError = false;
      }
    },
    /**
     * Handles when the user selects a new dimensioner key.
     * If the saved dimensioner key differs from the new value or the default value,
     * the username and password are cleared, and an error message is displayed
     * requiring re-entry of the Username and API Password key.
     * 
     * @param {string} newValue - The new dimensioner key selected by the user
    */
    selectDimensionerKey(newValue) {
      if (this.dbDimensionerKey != "" &&  this.dbDimensionerKey != this.defaultDimensioner && this.dbDimensionerKey != newValue) {
        this.username = "";
        this.password = "";
        this.dbDimensionerKey = newValue;
        this.$toast.error('Changing the dimensioner requires entering the Username and API Password key for security reasons.', {
          timeout: 5000,
        });
      }
    },
    /**
     * Toggles the visibility of the API password field.
    */
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
  },
  created() {
    this.getUser();
    this.getDimensioner();
  },
};
</script>

<style>
.reset-password-link {
  font-weight: 600;
  font-size: 13px;
  text-decoration-line: underline !important;
  color: #000000 !important;
  cursor: pointer;
}

.reset-password-link:hover {
  color: #000000 !important;
}
</style>
