<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <div id="card-element"></div>
      <p v-if="errorMessage" class="validate-error">{{ errorMessage }}</p>

      <div class="text-right">
        <button 
          type="submit" 
          class="btn btn-theme-24876E btn-primary mt-2" 
          :disabled="loading"
        >
          Add Card
        </button>
      </div>
    </form>    
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { Config } from "@/config/config";

export default {
  data() {
    return {
      stripe: null,
      cardElement: null,
      loading: false,
      errorMessage: null,
    };
  },
  async mounted() {
    // Load the Stripe object with the publishable key
    this.stripe = await loadStripe(Config.stripePublishableKey);

    // Create a Stripe elements instance
    const elements = this.stripe.elements();

     // Create a card element with the postal code field hidden
     this.cardElement = elements.create('card', {
      hidePostalCode: true,  // This ensures that the postal code field is hidden
    });

    // Mount the card element to the DOM
    this.cardElement.mount('#card-element');
  },
  methods: {
    /**
     * Handle form submission to create a Stripe token.
     * It shows a loading state, creates a token, and emits the result to the parent.
    */
    async handleSubmit() {
      this.loading = true;
      this.errorMessage = null;
      this.$emit('show-loading', true);

      // Create a token using the card element
      const { token, error } = await this.stripe.createToken(this.cardElement);

      // Handle errors in token creation
      if (error) {
        this.$emit('show-loading', false);
        this.errorMessage = error.message;
        this.loading = false;        
      } else {
        this.$emit('token-received', token);
        this.loading = false;
      }
    },
  },
};
</script>

<style>
#card-element {
  border: 1px solid #ccc;
  padding: 10px;
}
</style>
