<template>
  <div class="col-md-12 mb-3">
    <div 
      v-if="pickupDeliveryDate.addPickupDeliveryDateLabel"
    >
      <a 
        class="add-pickup-delivery-link"
        @click="openPickupDeliveryDate"
      >
       Add Pickup & Delivery times +
      </a>
    </div>
    <div v-else class="row">
      <div class="col-md-6">
        <div class="date-picker-label">Pickup Date</div>
        <date-picker 
          placeholder="Select date" 
          class="date-picker" 
          format="MMM DD, YYYY"
          v-model="localPickupDate"
          @input="updateParent"
          :disabled-date="isPickupDateDisabled" 
        ></date-picker>
      </div>
      <div class="col-md-6">
        <div class="date-picker-label">Delivery Date</div>
        <date-picker 
          placeholder="Select date" 
          class="date-picker" 
          format="MMM DD, YYYY"
          v-model="localDeliveryDate" 
          @input="updateParent('deliveryDate')"
          :disabled-date="isDeliveryDateDisabled"
          :disabled="!localPickupDate"
        ></date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  data: function() {
    return {
      localPickupDate: this.pickupDeliveryDate.pickupDate ? new Date(this.pickupDeliveryDate.pickupDate) : null,
      localDeliveryDate: this.pickupDeliveryDate.deliveryDate ? new Date(this.pickupDeliveryDate.deliveryDate) : null
    };
  },
  props: {
    pickupDeliveryDate: {
      type: Object,
    }
  },
  // watch on the pickup date, it should not be after than the delivery date
  watch: {
    'pickupDeliveryDate.pickupDate'(newPickupDate) {
      const pickup = new Date(newPickupDate);
      const delivery = new Date(this.pickupDeliveryDate.deliveryDate);
      
      // If the new pickup date is after the current delivery date, reset the delivery date
      if (pickup > delivery) {
        this.pickupDeliveryDate.deliveryDate = ''; // Reset delivery date
        this.localDeliveryDate = ''
      }
    },
    
    // Watch the pickupDeliveryDate prop for changes
    pickupDeliveryDate: {  // Watch for changes in the prop
      immediate: true,
      handler(newVal) {
        // Update localPickupDate if it exists
        if (newVal && newVal.pickupDate) {
          this.localPickupDate = new Date(newVal.pickupDate);  // Update local model if prop changes
        }
        // Update localDeliveryDate if it exists
        if (newVal.deliveryDate) {
          this.localDeliveryDate = new Date(newVal.deliveryDate);
        }
      }
    }
  },
  computed: {
    // Computed property to determine if the pickup date should be disabled
    isPickupDateDisabled() {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time to midnight
      return (date) => date < today; // Disable dates before today
    },

    // Computed property to determine if the delivery date should be disabled
    isDeliveryDateDisabled() {
      const pickupDate = new Date(this.pickupDeliveryDate.pickupDate);
      pickupDate.setHours(0, 0, 0, 0); // Reset time to midnight

      return (date) => {
        // Disable all dates before the selected pickup date
        if (this.pickupDeliveryDate.pickupDate) {
          return date < pickupDate; 
        }
        return false; // If no pickup date is set, do not disable any delivery dates
      };
    }
  },
  methods: {
    openPickupDeliveryDate() {
      this.pickupDeliveryDate.addPickupDeliveryDateLabel = false;
    },
    updateParent() {
      // Emit the update to the parent.
      this.$emit("update:pickupDeliveryDate", {
        ...this.pickupDeliveryDate,
        pickupDate: this.localPickupDate,
        deliveryDate: this.localDeliveryDate
      });
    }
  },
  created() {
    
  },
};
</script>
<style>

</style>
